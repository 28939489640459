import {
  Box,
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { TwDatePicker } from 'components/DatePicker';
import {Currency, Number, Percentage} from 'components/Number';
import {
  CurrencyNumberInput,
  PercentageNumberInput,
} from 'components/NumberInput';
import { HelperTooltip } from 'components/Tooltip';
import { DEAL_TYPE, FEE_TYPE } from 'constants/dealConstants';
import Decimal from 'decimal.js';
import { dateFormat } from 'helpers/propTypesHelper';
import { values as _values } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const RepaymentRow = ({ columns, repayment, index, onChange }) => {
  const isRepaymentDue = moment(repayment.date).isBefore(moment());
  const rowBg = isRepaymentDue ? 'horizonRed.100' : '';
  return (
    <Tr>
      {columns.map((column) => {
        return (
          <Td w={column.width} key={`${column.key}_${index}`} bg={rowBg}>
            <Box w={column.width}>
              {column.renderCell &&
                column.renderCell({
                  repayment,
                  index,
                  onChange: (value) => onChange(column.key, value),
                })}
            </Box>
          </Td>
        );
      })}
    </Tr>
  );
};

const AddRepaymentRow = ({
  columns,
  repaymentToAdd,
  onChange,
  onAddRepayment,
}) => {
  if (!repaymentToAdd) {
    return null;
  }

  return (
    <Tr>
      <Td>Add Repayment</Td>
      {columns
        .filter((column) => column.inAddRepaymentRow)
        .map((column) => (
          <Td key={column.key}>
            {column.renderCell({
              repayment: repaymentToAdd,
              onChange: (value) => onChange(column.key, value),
            })}
          </Td>
        ))}
      <Td>
        <Button
          variant={'outline'}
          onClick={onAddRepayment}
          isDisabled={_values(repaymentToAdd).some((v) => !v)}
        >
          Add
        </Button>
      </Td>
    </Tr>
  );
};

const TotalAmountRow = ({ columns, label, value }) => (
  <Tr>
    <Td></Td>
    <Td></Td>
    <Td colSpan={columns.length - 2}>
      <Flex position={'relative'} ml={4} fontWeight={800} fontSize={'15px'}>
        <Text position={'absolute'} left={'-100px'}>
          {label}:{' '}
        </Text>
        <Currency value={value} maximumFractionDigits={2} />
      </Flex>
    </Td>
  </Tr>
);

const InstallmentAmortizationSettings = ({
  repayments,
  amountSold,
  onChange,
  dealType,
  calcAmortizationRepaymentsSum,
  isTrueUp,
  feeType,
}) => {
  const [repaymentToAdd, setRepaymentToAdd] = useState();

  useEffect(() => {
    resetRepaymentToAdd();
  }, [isTrueUp]);

  const resetRepaymentToAdd = () => {
    let _initialRepaymentToAdd = {
      date: null,
    };

    if (dealType === DEAL_TYPE.ROAS) {
      _initialRepaymentToAdd.revenueStartDate = null;
      _initialRepaymentToAdd.revenueEndDate = null;
    }

    if (isTrueUp) {
      _initialRepaymentToAdd.trueUpPct = null;
    } else {
      _initialRepaymentToAdd.minAmount = null;
    }
    setRepaymentToAdd(_initialRepaymentToAdd);
  };

  const onFieldChange = (name, value, index) => {
    const newRepayments = [...repayments];
    newRepayments[index][name] = value;
    onChange(newRepayments);
  };

  const onAddRepayment = () => {
    const newRepayments = [...repayments, repaymentToAdd];
    onChange(newRepayments);
    resetRepaymentToAdd();
  };

  const onRemoveRepayment = (currentRepayments, index) => {
    const newRepayments = [...currentRepayments];
    newRepayments.splice(index, 1);
    onChange(newRepayments);
  };

  const createColumns = () => {
    const excludeDates = repayments.map((repayment) =>
      moment(repayment.date).format('YYYY-MM-DD')
    );

    let _columns = [
      {
        key: 'index',
        width: '100px',
        renderCell: ({ repayment, index }) => (
          <Text>{`Repayment ${index + 1}`}</Text>
        ),
      },
      {
        key: 'date',
        title: 'Repayment Date',
        width: '150px',
        inAddRepaymentRow: true,
        renderCell: ({ repayment, onChange }) => (
          <Box boxSizing="border-box">
            <TwDatePicker
              value={repayment.date}
              dateFormat={'MM/dd/yyyy'}
              hideCloseButton
              onChange={onChange}
              excludeDates={excludeDates}
              isDisabled={!!repayment.actualPaidAmount}
            />
          </Box>
        ),
      },
      {
        key: 'minAmount',
        title: 'Min Amount',
        width: '150px',
        inAddRepaymentRow: true,
        renderCell: ({ repayment, onChange }) => (
          <CurrencyNumberInput
            value={repayment.minAmount}
            onChange={onChange}
            isDisabled={!!repayment.actualPaidAmount || isTrueUp}
            showPrefix
          />
        ),
      },
    ];

    if (isTrueUp) {
      _columns.push({
        key: 'trueUpPct',
        title: 'True Up',
        tooltip: 'Enter the percentage of the Amount Sold to be repaid.',
        width: '150px',
        inAddRepaymentRow: true,
        renderCell: ({ repayment, onChange }) => (
          <Flex>
            <PercentageNumberInput
              value={repayment.trueUpPct}
              onChange={onChange}
              isDisabled={!!repayment.actualPaidAmount}
              showPrefix
            />
          </Flex>
        ),
      });
    }

    if (feeType === FEE_TYPE.PROGRESSIVE) {
      _columns.push({
        key: 'feePct',
        title: 'Fee %',
        width: '100px',
        inAddRepaymentRow: true,
        renderCell: ({ repayment, onChange }) => (
          <PercentageNumberInput
            value={repayment.feePct}
            onChange={onChange}
            isDisabled={!!repayment.actualPaidAmount}
            showPrefix
          />
        ),
      });
    }

    if (dealType === DEAL_TYPE.ROAS) {
      _columns.push(
        {
          key: 'revenueStartDate',
          title: 'Start Revenue Activity',
          width: '150px',
          inAddRepaymentRow: true,
          renderCell: ({ repayment, onChange }) => (
            <TwDatePicker
              value={repayment.revenueStartDate}
              dateFormat={'MM/dd/yyyy'}
              hideCloseButton
              onChange={onChange}
              isDisabled={!!repayment.actualPaidAmount}
            />
          ),
        },
        {
          key: 'revenueEndDate',
          title: 'End Revenue Activity',
          width: '150px',
          inAddRepaymentRow: true,
          renderCell: ({ repayment, onChange }) => (
            <TwDatePicker
              value={repayment.revenueEndDate}
              dateFormat={'MM/dd/yyyy'}
              hideCloseButton
              onChange={onChange}
              isDisabled={!!repayment.actualPaidAmount}
            />
          ),
        },
        {
          key: 'revenueSharePct',
          title: 'Revenue Share %',
          tooltip:
            "This percentage represents the cohort's funding divided by the marketing spend. It is calculated automatically.",
          width: '100px',
          renderCell: ({ repayment }) =>
            repayment.revenueSharePct === null ? (
              <Text>N/A</Text>
            ) : (
              <Percentage value={repayment.revenueSharePct} />
            ),
        },
        {
          key: 'revenueShareAmount',
          title: 'Revenue Share',
          tooltip:
            "This amount represents the cohort's Revenue, for the activity period, multiplied by the Revenue Share and Revenue Margin.",
          width: '100px',
          renderCell: ({ repayment }) =>
            repayment.revenueShareAmount === null ? (
              <Text>N/A</Text>
            ) : (
              <Currency
                value={repayment.revenueShareAmount}
                maximumFractionDigits={2}
              />
            ),
        }
      );
    }

    _columns.push(
      ...[
        {
          key: 'amountToPay',
          title: 'Expected Repayment',
          tooltip:
              "The projected amount to be repaid, determined by the minimum required repayment, actual revenue, and any remaining outstanding balance.",
          width: '120px',
          renderCell: ({ repayment }) =>
            repayment.amountToPay === null ? (
              <Box></Box>
            ) : (
              <Currency
                value={repayment.amountToPay}
                maximumFractionDigits={2}
              />
            ),
        },
        {
          key: 'actualPaidAmount',
          title: 'Actual Repayment',
          tooltip: "The actual amount the customer repaid.",
          width: '120px',
          renderCell: ({ repayment }) =>
            repayment.actualPaidAmount === null ? (
              <Box></Box>
            ) : (
              <Currency
                value={repayment.actualPaidAmount}
                maximumFractionDigits={2}
              />
            ),
        },
        {
          key: 'irr',
          title: 'IRR %',
          tooltip: "The IRR is calculated at the time of repayment, factoring in the predicted revenue.",
          width: '120px',
          renderCell: ({ repayment }) =>
              repayment.irrAtPayment === null ? (
                  <Box></Box>
              ) : (
                  <Percentage
                      value={repayment.irrAtPayment}
                  />
              ),
        },
        {
          key: 'durationAtPayment',
          title: 'Duration (months)',
          tooltip: "The Duration is calculated at the time of repayment, factoring in the predicted revenue. It is number of months.",
          width: '120px',
          renderCell: ({ repayment }) =>
              repayment.durationAtPayment === null ? (
                  <Box></Box>
              ) : (
                  <Number
                      value={repayment.durationAtPayment}
                  />
              ),
        },
        {
          key: 'remove',
          width: '100px',
          renderCell: ({ repayment, index }) => (
            <Box textAlign={'left'}>
              {!!repayment.actualPaidAmount ? (
                <Box></Box>
              ) : (
                <Button
                  variant={'link'}
                  color={'red.500'}
                  onClick={() => onRemoveRepayment(repayments, index)}
                >
                  Remove
                </Button>
              )}
            </Box>
          ),
        },
      ]
    );

    return _columns;
  };

  const columns = createColumns();

  if (!repayments || repayments.length === 0) {
    return null;
  }

  const repaymentAmountSum = calcAmortizationRepaymentsSum(repayments);

  return (
    <TableContainer ml={-8} maxW={'auto'}>
      <Table
        variant={'noPadding'}
        sx={{
          borderCollapse: 'separate',
          borderSpacing: '0 5px',
        }}
        __css={{
          'td, th': {
            verticalAlign: 'middle',
            padding: '5px 15px',
          },
        }}
      >
        <Thead>
          <Tr>
            {columns.map((column) => (
              <Th
                key={column.key}
                fontWeight={800}
                textTransform={'capitalize'}
                color={'inherit'}
                letterSpacing={'inherit'}
                fontSize={'inherit'}
              >
                <Flex align={'center'}>
                  <Text>{column.title}</Text>
                  {column.tooltip && <HelperTooltip content={column.tooltip} />}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {repayments.map((repayment, index) => (
            <RepaymentRow
              key={index}
              columns={columns}
              repayment={repayment}
              index={index}
              onChange={(name, value) => {
                onFieldChange(name, value, index);
              }}
            />
          ))}
          <TotalAmountRow
            columns={columns}
            label={'Remaining'}
            value={new Decimal(amountSold).minus(repaymentAmountSum).toNumber()}
          />
          <TotalAmountRow
            columns={columns}
            label={'Amount Sold'}
            value={amountSold}
          />
          <AddRepaymentRow
            columns={columns}
            repaymentToAdd={repaymentToAdd}
            onChange={(name, value) =>
              setRepaymentToAdd({ ...repaymentToAdd, [name]: value })
            }
            onAddRepayment={onAddRepayment}
          />
        </Tbody>
      </Table>
    </TableContainer>
  );
};

InstallmentAmortizationSettings.propTypes = {
  repayments: PropTypes.arrayOf(
    PropTypes.shape({
      date: dateFormat,
      minAmount: PropTypes.number,
    })
  ),
  amountSold: PropTypes.number,
  onChange: PropTypes.func,
  dealType: PropTypes.string,
  feeType: PropTypes.string,
  calcAmortizationRepaymentsSum: PropTypes.func,
  isTrueUp: PropTypes.bool,
};

export default InstallmentAmortizationSettings;
