import { Tag } from '@chakra-ui/react';
import { INSTALLMENT_TYPE_META } from 'constants/dealConstants';
import PropTypes from 'prop-types';

const InstallmentTypeTag = ({type}) => {
  const t = INSTALLMENT_TYPE_META[type]
  return (
    <Tag
      fontSize={'inherit'}
      px={8}
      py={2}
      bg={t.color}
      whiteSpace={'nowrap'}
      size={'md'}
    >
      {INSTALLMENT_TYPE_META[type].label}
    </Tag>
  );
};

InstallmentTypeTag.propTypes = {
  type: PropTypes.string.isRequired,
};

export default InstallmentTypeTag;
